<template>
  <div class="research">
    <div class="research__meta">

      <span class="research__subheading">Blabla</span>
      <h1>Engagement</h1>

    </div>

      <div class="research__content">

        <div class="research__content-menu">
          <ul class="menu__container">

            <li>Conspiracy</li>
            <li>QAnon</li>
            <li>Conspiracy</li>

          </ul>
        </div>

        <div class="research__content-intro">
          <p>These graphs show the amount of views, ratings and comments.</p>
    
        </div>


  <div class="research__visuals">
    <div class="visuals-graph">


      <div class="graph" v-for="graph in formattedGraphs" :key="graph.channel_id">
        <h3 class="graph-heading">{{graph.title}}</h3>
        <div class="graph-container">
          <canvas :ref="graph.graph_id"></canvas>
        </div>
        
      </div>
      <div class="graph"></div>
      <div class="graph"></div>
      <div class="graph"></div>
      <div class="graph"></div>
      <div class="graph"></div>
    </div>
    <p class="analysis">blabla</p>

        </div>


      </div>
    



        
      

       
       




  </div>
</template>

<script>
import axios from 'axios';
// var Chart = require('chart.js');
import Chart from 'chart.js'

export default {
  name: "Engagement",
  data: function() {
    return {
      graphs: {},
      formattedGraphs: [],
      charts: {}
    }
  },
  beforeMount() {
    let _this = this;
    axios.get(`https://y1yd1oyc37.execute-api.us-east-1.amazonaws.com/getStats/cat/${this.$route.params.id}`)
      .then(function (response) {
        // console.log(this);
        _this.graphs = response.data;
        _this.generateGraphs();

        // handle success
        // console.log(response);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {

        // always executed
      });
  },
  mounted() {
  },
  methods: {
    randomString() {
      const characters ='abcdefghijklmnopqrstuvwxyz';

      let result = '';
      const charactersLength = characters.length;
      for ( let i = 0; i < 20; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }

      return result;
    },
    generateGraphs() {
      String.prototype.replaceAt = function(index, replacement) {
        return this.substr(0, index) + replacement + this.substr(index + replacement.length);
      }

      // let graphs = [];

      this.graphs.forEach((channel) => {
        let obj = {}

        obj.title = channel.title;
        obj.channel_id = channel.channel_id;
        obj.graph_id = this.randomString();
        obj.months = [];
        obj.avg_commentscount_views = [];
        obj.avg_ratingcount_views = [];
        obj.count_videos = [];
        obj.sum_views = [];
        obj.sum_rating_counts = [];
        obj.sum_comments_counts = [];

        channel.data.forEach((months) => {
          obj.months.push(new Date(months.published_at.replaceAt(8, "01").substring(0, 10)));
          obj.avg_commentscount_views.push((months.avg_commentscount_views*100).toFixed(2));
          obj.avg_ratingcount_views.push((months.avg_ratingcount_views*100).toFixed(2));
          obj.count_videos.push(months.count_videos);
          obj.sum_views.push(months.sum_views);
          obj.sum_rating_counts.push(months.sum_rating_counts);
          obj.sum_comments_counts.push(months.sum_comments_count);
        })

        this.formattedGraphs.push(obj);
      })

// console.log(graphs);

setTimeout(()=> { 



      this.formattedGraphs.forEach((newGraph) => {

        console.log(newGraph)
        console.log(this.$refs);
        console.log(this.$refs[newGraph.graph_id])

new Chart(this.$refs[newGraph.graph_id], {
    type: 'line',
    data: {
        labels: newGraph.months,
        // labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
        datasets: [{
            label: 'Comment engagement',
            data: newGraph.avg_commentscount_views,
            // data: [12, 19, 3, 5, 2, 3],
            backgroundColor: [
                'rgba(153, 102, 255, 0.2)'
            ],
            borderColor: [
                'rgba(75, 192, 192, 1)',
            ],
            borderWidth: 1
        }, {
            label: 'Vote engagement',
            data: newGraph.avg_ratingcount_views,
            // data: [12, 19, 3, 5, 2, 3],
            backgroundColor: 
                'rgba(255, 159, 64, 0.2)'
            ,
            borderColor: [
                'rgba(255, 99, 132, 1)',
            ],
            borderWidth: 1
        }]
    },
    options: {

        legend: {
            labels: {
                // This more specific font property overrides the global property
                defaultFontFamily: "PlexMonoRegular",
                fontColor: 'black'
            }
        },
        scales: {
            xAxes: [{
                type: 'time',
                time: {
                    unit: 'month'
                }
            }],
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                    suggestedMax: 40
                },
                gridLines: {
                zeroLineBorderDash: [1,3]
              }
            },
            ]
        }
    }
});


      })

      }, 500);





      // new Chart(ctx, config);




    }
  }

}
</script>

<style>
.research__meta {
  height: 8rem;
  border-bottom: 1px solid #414141;
  padding: 3rem 0; 
}

h1 {
  font-family: 'Ilisarniq-Demi';
  font-size: 3.625rem;
  margin: 0;
  color: white;
}

.research__subheading {
  font-family: 'PlexMonoRegular';
  font-size: 1rem;
  color: #C8C8C8;
  text-transform: uppercase;
}

.research__content {
  display: grid;
  grid-template-columns: repeat(12, [col-start] 1fr);
  column-gap: 1rem;
  row-gap: 0;
}

.research__content-menu {
  grid-column: col-start / span 2;
}

  .menu__container {
    font-family: 'PlexMonoRegular';
    color: #C8C8C8;
    font-size: 1rem;
    margin: 6.33rem 0;
    padding: 0;
    text-transform: uppercase;
    list-style-type: none;
    position: fixed;
  }

  .menu__container li {
    margin-top: .66rem;
    line-height: 24px;
  }

.research__content-intro {
  grid-column: 3 / span 5;
  padding: 4rem 0;
  border-left: 1px solid #414141;
}

.research__content-intro p {
  font-family: 'Ilisarniq-Regular';
  font-size: 1.75rem;
  color: white;
}




.research__visuals {
  grid-column: 3 / span 10;  
}

.visuals-graph {
  display: flex;
  background: #C4C4C4;
  flex-wrap: wrap;
  align-items: flex-start;
}

.graph {
  height: 250px;
  width: 33.33%;
  border-right: 1px solid #414141;
  border-bottom: 1px solid #414141;
  flex-shrink: 0;
}

.graph-rank {
  font-family: 'PlexMonoRegular';
  font-size: 1.5rem;
  text-align: center;
  line-height: 3rem;
  width: 3rem;
  height: 3rem;
  background: white;
  margin-top: -1.5rem;
}

.graph-heading {
  font-family: 'PlexMonoRegular';
  font-size: 1.1rem;
  color: black;
  font-weight: 100;
  margin-left: 2rem;
  margin-bottom: 0;
}
</style>