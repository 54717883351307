<template>
  <div class="research__visuals">
    <div class="visuals-graph">


      <div class="graph" ref="graph">
        <div class="graph-rank">Views</div>
        <h3 class="graph-heading">Great Reset/Global Elite</h3>
        <div class="graph-container">
          <canvas ref="global"></canvas>
        </div>
      </div>

      <div class="graph" ref="graph">
        <h3 class="graph-heading">Higher Conciousness</h3>
        <div class="graph-container">
          <canvas ref="spirit"></canvas>
        </div>
      </div>

      <div class="graph" ref="graph">
        <h3 class="graph-heading">Biblical Times</h3>
        <div class="graph-container">
          <canvas ref="biblical"></canvas>
        </div>
      </div>



      <div class="graph">
        <div class="graph-rank">Mentions</div>
        <h3 class="graph-heading">Great Reset/Global Elite</h3>
        <div class="graph-container">
          <canvas ref="mentionGlobal"></canvas>
        </div>
      </div>
      <div class="graph">
        <h3 class="graph-heading">Higher Conciousness</h3>
        <div class="graph-container">
          <canvas ref="mentionSpirit"></canvas>
        </div>
      </div>
      <div class="graph">
        <h3 class="graph-heading">Biblical Times</h3>
        <div class="graph-container">
          <canvas ref="mentionBiblical"></canvas>
        </div>
      </div>
    </div>
    <p class="description" v-html="reportData.description">{{reportData.description}}</p>

    <Analysis :analysis="reportData.analysis" :methodology="reportData.methodology" />

  </div>

</template>

<script>
// import * as d3 from "d3";
// import MG from 'metrics-graphics';
import testData from '../../assets/data/test.json';
import Analysis from '../Analysis.vue';

import Chart from 'chart.js'


export default {
  name: 'Trends',
  props: ['reportData'],
  components: {
    Analysis
  },
  beforeMount() {
    console.log(this.reportData);
  },
  mounted() {
    console.log(testData)


    let viewData = [{"theme": "global elite", "mentions": [{"date": "2021-5-1", "value": 581272738}, {"date": "2021-4-1", "value": 365390530}, {"date": "2021-3-1", "value": 166256661}, {"date": "2021-2-1", "value": 243705826}, {"date": "2021-1-1", "value": 299745073}, {"date": "2020-12-1", "value": 227474348}, {"date": "2020-11-1", "value": 188152659}, {"date": "2020-10-1", "value": 161244620}, {"date": "2020-9-1", "value": 146183534}, {"date": "2020-8-1", "value": 146339152}, {"date": "2020-7-1", "value": 214196063}, {"date": "2020-6-1", "value": 141923796}, {"date": "2020-5-1", "value": 80499586}]}, {"theme": "higher consciousness", "mentions": [{"date": "2021-5-1", "value": 552257039}, {"date": "2021-4-1", "value": 361694313}, {"date": "2021-3-1", "value": 183914123}, {"date": "2021-2-1", "value": 276305134}, {"date": "2021-1-1", "value": 450243095}, {"date": "2020-12-1", "value": 128457745}, {"date": "2020-11-1", "value": 167882701}, {"date": "2020-10-1", "value": 45873651}, {"date": "2020-9-1", "value": 112959259}, {"date": "2020-8-1", "value": 68681767}, {"date": "2020-7-1", "value": 305038264}, {"date": "2020-6-1", "value": 23363512}, {"date": "2020-5-1", "value": 83446165}]}, {"theme": "biblical times", "mentions": [{"date": "2021-5-1", "value": 155893205}, {"date": "2021-4-1", "value": 120618249}, {"date": "2021-3-1", "value": 82348917}, {"date": "2021-2-1", "value": 68224218}, {"date": "2021-1-1", "value": 377920251}, {"date": "2020-12-1", "value": 59284732}, {"date": "2020-11-1", "value": 42097919}, {"date": "2020-10-1", "value": 22358110}, {"date": "2020-9-1", "value": 18169946}, {"date": "2020-8-1", "value": 18658227}, {"date": "2020-7-1", "value": 62863529}, {"date": "2020-6-1", "value": 4744973}, {"date": "2020-5-1", "value": 20009897}]}];

    let mentionData = [{"theme": "global elite", "mentions": [{"date": "2021-5-1", "value": 10533}, {"date": "2021-4-1", "value": 6351}, {"date": "2021-3-1", "value": 3863}, {"date": "2021-2-1", "value": 3222}, {"date": "2021-1-1", "value": 3241}, {"date": "2020-12-1", "value": 3228}, {"date": "2020-11-1", "value": 2262}, {"date": "2020-10-1", "value": 1872}, {"date": "2020-9-1", "value": 1857}, {"date": "2020-8-1", "value": 1980}, {"date": "2020-7-1", "value": 2366}, {"date": "2020-6-1", "value": 1322}, {"date": "2020-5-1", "value": 1324}]}, {"theme": "higher consciousness", "mentions": [{"date": "2021-5-1", "value": 10684}, {"date": "2021-4-1", "value": 7187}, {"date": "2021-3-1", "value": 5712}, {"date": "2021-2-1", "value": 3869}, {"date": "2021-1-1", "value": 3399}, {"date": "2020-12-1", "value": 2929}, {"date": "2020-11-1", "value": 1584}, {"date": "2020-10-1", "value": 1446}, {"date": "2020-9-1", "value": 1915}, {"date": "2020-8-1", "value": 1865}, {"date": "2020-7-1", "value": 1390}, {"date": "2020-6-1", "value": 831}, {"date": "2020-5-1", "value": 1111}]}, {"theme": "biblical times", "mentions": [{"date": "2021-5-1", "value": 2373}, {"date": "2021-4-1", "value": 1673}, {"date": "2021-3-1", "value": 1296}, {"date": "2021-2-1", "value": 1283}, {"date": "2021-1-1", "value": 1007}, {"date": "2020-12-1", "value": 880}, {"date": "2020-11-1", "value": 543}, {"date": "2020-10-1", "value": 249}, {"date": "2020-9-1", "value": 442}, {"date": "2020-8-1", "value": 418}, {"date": "2020-7-1", "value": 724}, {"date": "2020-6-1", "value": 83}, {"date": "2020-5-1", "value": 354}]}]


    let labels = [];
    let views = [];
    let viewsSpiritual = [];
    let viewsBiblical = [];

    let mentionsGlobal = [];
    let mentionsSpiritual = [];
    let mentionsBiblical = [];

    viewData[0].mentions.forEach((newData) => {
      labels.push(newData.date)
      views.push(newData.value)
    })

    viewData[1].mentions.forEach((newData) => {
      viewsSpiritual.push(newData.value)
    })

    viewData[2].mentions.forEach((newData) => {
      viewsBiblical.push(newData.value)
    })

    mentionData[0].mentions.forEach((newData) => {
      mentionsGlobal.push(newData.value)
    })

    mentionData[1].mentions.forEach((newData) => {
      mentionsSpiritual.push(newData.value)
    })

    mentionData[2].mentions.forEach((newData) => {
      mentionsBiblical.push(newData.value)
    })

setTimeout(() => {

  let allGraphs = [{
    ref: this.$refs.global,
    data: views
  },
  {
    ref: this.$refs.spirit,
    data: viewsSpiritual
  },
  {
    ref: this.$refs.biblical,
    data: viewsBiblical
  }
  ]


    let mentionGraphs = [{
    ref: this.$refs.mentionGlobal,
    data: mentionsGlobal
  },
  {
    ref: this.$refs.mentionSpirit,
    data: mentionsSpiritual
  },
  {
    ref: this.$refs.mentionBiblical,
    data: mentionsBiblical
  }
  ]

  allGraphs.forEach((graph) => {

    new Chart(graph.ref, {
      type: 'line',
      data: {
        labels: labels,
        datasets: [{
          label: 'Views',
          data: graph.data,
          backgroundColor: [
            'rgba(153, 102, 255, 0.2)'
          ],
          borderColor: [
            '#4f23ff',
          ],
          borderWidth: 1
        }]
      },
      options: {
        elements: {
          point: {
            radius: 4,
            backgroundColor: 'rgba(79,35,255, .5)'
          }
        },
        layout: {
          padding: {
            left: -10,
              right: 0,
              top: 20,
              bottom: -20
          }
        },
        legend: {
          display: false,
        },
        scales: {
          xAxes: [{
            type: 'time',
            ticks: {
              display: false,
              maxTicksLimit: 5
            },
            time: {
              unit: 'month'
            }
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true,
              display: false,
              suggestedMax: 600000000
            },
            gridLines: {
              borderDash: [2,5],
            }
          }, ]
        }
      }
    });


  })




    mentionGraphs.forEach((graph) => {

    new Chart(graph.ref, {
      type: 'line',
      data: {
        labels: labels,
        datasets: [{
          label: 'Mentions',
          data: graph.data,
          backgroundColor: [
            'rgba(244, 54, 214, 0.2)'
          ],
          borderColor: [
            '#F436D6',
          ],
          borderWidth: 1
        }]
      },
      options: {
        elements: {
          point: {
            radius: 4,
            backgroundColor: 'rgba(244, 54, 214, 0.5)'
          }
        },
        layout: {
          padding: {
            left: -10,
              right: 0,
              top: 20,
              bottom: -20
          }
        },
        legend: {
          display: false,
        },
        scales: {
          xAxes: [{
            type: 'time',
            ticks: {
              display: false,
              maxTicksLimit: 5
            },
            time: {
              unit: 'month'
            }
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true,
              display: false,
              suggestedMax: 10000
            },
            gridLines: {
              borderDash: [2,5],
            }
          }, ]
        }
      }
    });


  })

}, 500);




    

    // let newData = MG.convert.date(viewData[0].mentions, 'date');
    // console.log(newData)

    // MG.data_graphic({
    //   data: newData,
    //   area: false,
    //   animate_on_load: true,
    //   width:  widthContainer - (16*2),
    //   height: 200,
    //   right: -40,
    //   xax_count: 4,
    //   min_y: 0,
    //   max_y: 600000000,
    //   target: '.graph-container',
    //   y_axis: false
    // });

  }

}
</script>

<style>
@media (max-width: 992px) {
  .graph {
    width: 50% !important;
    height: 200px !important;
  }
}

@media (max-width: 768px) {
  /* .visuals-graph {
    flex-direction: column;
  } */

  /* .graph {
    width: 100% !important;
  } */
}

.research__visuals {
  grid-column: 3 / span 10;  
}

.visuals-graph {
  display: flex;
  background: #C4C4C4;
  flex-wrap: wrap;
  align-items: flex-start;
}

.graph {
  height: 250px;
  width: 33.33%;
  border-right: 1px solid #414141;
  border-bottom: 1px solid #414141;
  flex-shrink: 0;
  display: flex;
  align-content: flex-end;
  flex-direction: column;
  justify-content: space-between;
}

.graph-rank {
  font-family: 'PlexMonoRegular';
  font-size: 1rem;
  line-height: 2.5rem;
  height: 2.5rem;
  background: white;
  margin-top: -1.25rem !important;
  display: table;
  padding: 0 1rem;
  z-index: 100;
  text-transform: uppercase;
  position: absolute;
}

.graph-heading {
  font-family: 'Ilisarniq-Regular';
  font-size: 1.1rem;
  color: black;
  font-weight: 100;
  margin-top: 2rem;
  margin-left: 1rem;
  margin-bottom: 0;
}

.graph-container svg {
  margin-left: -1rem;
}

i {
  background-color: #565656;
  padding: .1rem .7rem;
  border-radius: 5px;
}

</style>