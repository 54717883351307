<template>
  <div class="removals__container appendix">
    <h4>Appendix and Further Discussion</h4>

    <h5 id="topic">Topic graphs</h5>

    <p>The full list of search terms used for each of the themes is shown below:</p>

    <ul>
      <li>Global Elite: “great reset”, “new normal”,  “build back better”, “g7”, “Bill Gates”, “Epstein”, “billionaire”, “elite pedophile ring”, “billionaires” , “bilderberg”, “global elite”, “soros”, “new world order”, “globalism”</li>
      <li>Higher Consciousness: “spiritual warfare”, “consciousness”, “higher consciousness”, “beyond the veil”, “behind the veil”, “behind a veil”, “matrix”, “simulation”, “spiritual awakening”, “vibration”, “manifestation”, “raising vibration”, “intent”, “manifest”</li>
      <li>Biblical Times: “jesus is coming”, “end time”, “end times”, “end of days”, “end of times”, “second coming”, “has risen”, “revelation”, “revelations”</li>
    </ul>

      <p>The graphs were generated using videos from all tracked channels, not limited to specific categories of content. We simply looked for the occurrence of these phrases, not the context in which they were used. Despite casting an overly broad net, we feel this is still a good measure of the popularity of certain discussion terms. We are investigating methods for recognizing topics and discussions without manual analysis. 
      </p>

    <h5 id="removal">Removal</h5>

    <p>For removal, we specifically looked at the alt-right, conspiracy, Qanon, alt-health, and spirituality focused channels. As mentioned above, some videos have been removed by the site and some by the creators themselves. While we found removed videos to be consistently more popular than their still-live counterparts, we currently have little data on why the videos were removed in the first place. YouTube’s lack of transparency around moderation was one of our major motivations for starting this project. As such, future reports will investigate to what extent removal is used by creators to avoid moderation and how quickly YouTube responds to problematic content.
      </p>

    <h5 id="offsite">Offsite links</h5>

    <p>Our current methodology simply checks for the existence of these social media links. Some video descriptions will link to other social media posts outside of referencing a creator’s alternative profile. However, from manual checking we’ve found the vast majority of these links are creators promoting their profiles on alternative sites. Many creators also use url shorteners or aggregators like bitly or linktree to obfuscate detection and avoid censorship. Future analysis will seek to follow these links to other sites as well. 
      </p>

    <h5 id="classification">Community classification</h5>

    <p>We built our list of channels from some academic papers <span>(https://arxiv.org/abs/1908.08313 and https://arxiv.org/abs/1912.11211)</span> and manually searched for other related channels. Our classification comes from manual review or the determination of the papers we found. We are adding automatic discovery of channels, and plan on using an implementation of the methods in this paper <span>(https://arxiv.org/abs/2010.09892)</span> for automatic tagging/classification of newly discovered channels. For a full list of channels please contact us at <span>info@raditube.com</span>.
      </p>



  </div>
</template>

<script>
export default {
  name: 'Appendix'

}
</script>

<style>
@media (max-width: 992px) {
  .appendix {
    width: 100% !important;
    border: none;
    padding-left: none;
  }
}

.appendix {
  margin-top: 3rem;
  border-left: 4px solid #171717;
  padding-left: 2rem;
  width: 66%;
  padding-bottom: 4rem;
  margin-bottom: 4rem;
}

.appendix h4 {
  font-family: 'PlexMonoRegular';
  font-size: 1.5rem;
  color: #D6D6D6;
  text-transform: uppercase;
  font-weight: 100;
}

.appendix h5 {
  font-family: 'PlexMonoRegular';
  font-size: 1rem;
  color: #D6D6D6;
  background-color: #171717;
  font-weight: 100;
  display: table;
  padding: .5rem 1rem;
}

.appendix p, .appendix ul li {
  font-family: 'Ilisarniq-Regular';
  font-size: 1rem;
  margin: 0;
  color: #a5a5a5;
  font-weight: 100;
  line-height: 1.5rem;
}

.appendix p span {
  font-family: 'PlexMonoRegular';
  font-size: .9rem;
      background-color: #151515;
    padding: .05rem .3rem;
} 
</style>