<template>
  <div class="analysis__container">
    <div class="analysis__50">
      <h3>Analysis</h3>
      <p class="analysis" v-html="Analysis">{{Analysis}}</p>
    </div>

    <div class="methodology__50">
      <div class="methodology__container">
      <h3>Methodology</h3>
      <p v-html="Methodology">{{Methodology}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Analysis",
  props: [
    'Analysis', 'Methodology'
  ]

}
</script>

<style>

</style>