<template>
  <div class="research">
    <div class="research__meta">

      <span class="research__subheading">{{report.subheading}}</span>
      <h1>{{report.title}}</h1>

      <div class="research__content">

        <div class="research__content-menu">
          <ul class="menu__container">
            <li v-bind:class="{ 'menu--active': currentView === 'introduction' }">Introduction</li>

            <li v-bind:class="{ 'menu--active': currentView === block.type }" v-for="block in blocks" :key="block.type">
              {{ block.title }}
            </li>

          </ul>
        </div>

        <div class="research__content-intro">
          <p>{{report.introduction}}</p>
        </div>

        <div class="research__content-meta">
          <ul class="content-metalist">
            <li class="content-metakey">Publication date</li>
            <li class="content-metavalue">{{report.displayPublicationDate}}</li>
          </ul>

          <ul class="content-metalist">
            <li class="content-metakey">Editors</li>
            <li class="content-metavalue" v-for="editor in report.editors" :key="editor">
              {{ editor }}
            </li>
          </ul>

          <ul class="content-metalist">
            <li class="content-metakey">Share</li>
            <li class="content-metavalue icons">
              <a :href="'http://www.twitter.com/share?text=' + report.socials.twitter.shareText + '&url=https://www.raditube.com'" target="_blank">
                <span class="twitter-icon"></span>
              </a>
              <a href="https://www.facebook.com/sharer/sharer.php?u=https://reports.raditube.com">
                <span class="facebook-icon"></span>
              </a>
            </li>
          </ul>
        </div>


        <Trends :reportData="report.blocks[0]" :class="blocks[0].type" />

        <!-- <Engagement :reportData="report.blocks[1]" :class="blocks[1].type" /> -->

    

        <Platforms :reportData="report.blocks[1]" :class="blocks[1].type" />

        <Removals :reportData="report.blocks[2]" :class="blocks[2].type" :showSide="true" />
        <Removals :reportData="report.blocks[3]" :class="blocks[3].type" :showSide="false" />

        <Appendix />




      
      </div>


    </div>
  </div>
</template>

<script>
import amplitude from "amplitude-js";

import enterView from 'enter-view'; 
import Report from '../content/report_june_2021.yaml';

import Trends from '../components/trends/Trends.vue';
// import Engagement from '../components/engagement/Engaging.vue';
import Platforms from '../components/Platforms.vue';
import Removals from "../components/Removals.vue";
import Appendix from "../components/Appendix.vue";

let ROOT_PATH = 'https://reports.raditube.com'

export default {
  name: 'ResearchJune',
  data() {
    return {
      amplitudeInstance: amplitude.getInstance(),
      currentView: "introduction",
      blocks: [],
      report: {},
      logo: ROOT_PATH + require('../assets/socials/twitter_may21.png')
    }
  },
  metaInfo() {
    return {
      meta: [
        // Twitter Card
        {name: 'twitter:card', content: 'summary'},
        {name: 'twitter:title', content: 'RadiTube Reports'},
        {name: 'twitter:description', content: 'RadiTube\'s Disinformation Report for May 2021'},
        // image must be an absolute path
        {name: 'twitter:image', content: 'https://i.imgur.com/td244uo.png'}
      ]
    }
  },
  components: {
    // Trends, Engagement, Platforms, Removals, Appendix
    Trends, Platforms, Removals, Appendix
  },
  mounted() {
    this.amplitudeInstance.init("b79dc91978a6ebd27882e8f581c2c36d");
    this.amplitudeInstance.logEvent("Report — FEBMARMAY")

    this.report = Report;

    this.setupScroll()
  },
  methods: {
    setupScroll() {
      // console.log(ReportJune)

      Report.blocks.forEach( (item )=> {
        this.blocks.push({
          type: item.type,
          title: item.title
        })
      }) 

      let _this = this;

      window.setTimeout(() => {
        Report.blocks.forEach( (item, index)=> {

          console.log(item);

        enterView({
          selector: `.${item.type}`,
          enter: function() {
            _this.currentView = item.type;
            _this.amplitudeInstance.logEvent(`Report — ENTER ${item.type}`)
          },
          exit: function() {
            if(index > 0) {
              _this.currentView = Report.blocks[index-1].type;
              _this.amplitudeInstance.logEvent(`Report — EXIT ${Report.blocks[index-1].type}`)
            } else {
              _this.currentView = "introduction"
            }
          },
          offset: 0.5
        });
      }) 


      },1000)



    },
  }

}
</script>

<style>
.research__meta {
  height: 8rem;
  border-bottom: 1px solid #414141;
  padding: 3rem 0; 
}

h1 {
  font-family: 'Ilisarniq-Demi';
  font-size: 3.625rem;
  margin: 0;
  color: white;
}

.research__subheading {
  font-family: 'PlexMonoRegular';
  font-size: 1rem;
  color: #C8C8C8;
  text-transform: uppercase;
}

.research__content {
  display: grid;
  grid-template-columns: repeat(12, [col-start] 1fr);
  column-gap: 1rem;
  row-gap: 0;
}

.research__content-menu {
  grid-column: col-start / span 2;
}

  .menu__container {
    font-family: 'PlexMonoRegular';
    color: #C8C8C8;
    font-size: 1rem;
    margin: 6.33rem 0;
    padding: 0;
    text-transform: uppercase;
    list-style-type: none;
    position: fixed;
  }

  .menu__container li {
    margin-top: .66rem;
    line-height: 24px;
  }

  .menu--active {
    background-image: url('../assets/icons/rectangle.svg');
    background-repeat: no-repeat;
    color: white;
    padding-left: 2rem;
    margin-left: -.7rem;
    line-height: 24px;
  }

.research__content-intro {
  grid-column: 3 / span 5;
  padding: 4rem 0;
  border-left: 1px solid #414141;
}

.research__content-intro p {
  font-family: 'Ilisarniq-Regular';
  font-size: 1.75rem;
  color: white;
}

.research__content-meta {
  grid-column: 9 / span 4;
  padding: 6rem 0;
  border-left: 1px solid #414141;
  margin-left: -1rem;
  padding-left: 1rem;
}

  .content-metalist {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .content-metalist:nth-child(2), .content-metalist:nth-child(3) {
    margin-top: 2rem;
  }

  .content-metakey {
    font-family: 'PlexMonoRegular';
    color: #C8C8C8;
    text-transform: uppercase;
    font-size: 1rem;
  }

  .content-metavalue {
    font-family: 'Ilisarniq-Regular';
    color: white;
    font-size: 1.3125rem;
    margin-top: .5rem;
  }

  .icons {
    display: flex;
  }

  .twitter-icon {
    width:31px;
    height: 31px;
    display: block;
    background: url('../assets/icons/twitter.svg');
    background-repeat: no-repeat;
    background-position-y: 2px;
  }

  .facebook-icon {
    width:31px;
    height: 31px;
    display: block;
    background: url('../assets/icons/facebook.svg');
    background-repeat: no-repeat;
    margin-left: .5rem;
  }

.description {
  font-family: 'Ilisarniq-Regular';
  color: white;
  font-size: 2.25rem;
  margin-top: 6rem;
  width: 80%;;
}
</style>