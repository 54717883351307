<template>
  <div class="removals__container">
    <div class="description">
      <p>{{reportData.description}}</p>
    </div>  


    <div class="analysis__container">
      <div class="analysis__50">
        <p class="analysis" v-html="reportData.analysis">{{reportData.analysis}}</p>
      </div>

        <div class="methodology__50" v-if="showSide">
          <h3>Numbers</h3>
          <p>Stats</p>
        </div>

    </div>
    
  </div>
</template>

<script>
export default {
  name: 'Removals',
  props: ['reportData', 'showSide']
}
</script>

<style>
.removals__container {
  grid-column: 3 / span 10;  
}

</style>