<template>
  <div class="research__platforms">
      <div class="description">
        <p>{{reportData.description}}</p>
      </div>  

      <div>

        <div class="platforms__row header">
          <div class="row_cat">aug '20</div>
          <div class="row_cat">nov '20</div>
          <div class="row_cat">feb '21</div>
          <div class="row_cat">may '21</div>
        </div>

        <div class="platforms__row" v-for="pitem in all_platforms" :key="pitem">
          <div class="platform__entry-container" :class="pitem" v-for="(item, index) in platforms" :key="index">
            <div class="platform__entry" :class="pitem" :style="{ minHeight: `${(platforms[index].platforms[pitem]/platforms[index].total_mentions_descriptions*100)*2}px`}">
              <h4>{{pitem}}</h4>
              <span>{{(platforms[index].platforms[pitem]/platforms[index].total_mentions_descriptions*100).toFixed(2)}}%</span>
            </div>
          </div>
        </div>

      </div>

      <div class="analysis__container">
        <div class="analysis__50">
          <h3>Analysis</h3>
          <p class="analysis" v-html="reportData.analysis" >{{reportData.analysis}}</p>
        </div>

        <div class="methodology__50">
          <div class="methodology__container">
          <h3>Methodology</h3>
          <p>{{reportData.methodology}}</p>
          </div>
        </div>

        
      </div>
  </div>
</template>

<script>
import platform_data from "../content/platforms.json";

export default {
  name: 'Trends',
  props: ['reportData'],
  data: function() {
    return {
      all_platforms: ['twitter', 'facebook', 'instagram', 'twitch', 'gab', 'parler', 'rumble', 'bitchute', 'odysee', 'locals', 'dlive', 'brighteon', 'lbry', 'trovo', 'ugetube'],
      platforms: platform_data
    }
  },
  beforeMount() {
    console.log(this.reportData);
  }
}
</script>

<style>
@media (max-width: 768px) {

.platform__entry-container:nth-child(n+2) h4 {
  display: none;
}

.platform__entry-container:nth-child(n+2) {
  background: none;
}

.platform__entry-container:nth-child(n+2) .platform__entry {
  background-image: none !important;
}

.platform__entry-container:nth-child(n+2) .platform__entry span {
 margin-left: 2rem !important;
}

}

@media (max-width: 992px) {
  .analysis__container {
    flex-direction: column;
  }

  .analysis__50, .methodology__50 {
    width: 100% !important;
  }
}

.research__platforms {
  grid-column: 3 / span 10;  
}

.visuals-graph {
  display: flex;
  background: #C4C4C4;
  flex-wrap: wrap;
  align-items: flex-start;
}

.platforms__row {
  display: flex;
  background: black;
  flex-wrap: wrap;
  align-items: flex-start;
  border-top: 1px solid #414141;
  margin-top: .5rem;
}

.platforms__row:nth-child(2) {
  margin-top: 0;
}

.header {
  border-top: none;
  border-bottom: 0px;
  margin-top: 6rem;
}

.row_cat {
  width: 25%;
  font-family: "PlexMonoRegular";
  font-size: 1.25rem;
  color: #D6D6D6;
  line-height: 4rem;
  padding-left: 1rem;
  border-left: 1px solid #414141;
}

.platform__entry-container {
  width: 25%;
  border-right: 1px solid #414141;
  background-position: 1rem 1rem;
  background-repeat: no-repeat;
  background-size: 1.5rem;
  min-height: 5rem;
}

.platform__entry {
  background: #373737;
  color: white;
  background-position: 1rem 1rem;
  background-repeat: no-repeat;
  background-size: 1.5rem;
}

.platform__entry h4 {
  font-family: 'Ilisarniq-Regular';
  font-size: 1.5625rem;
  margin: .5rem 4rem;
  font-weight: 100;
  position: absolute;
}

.platform__entry span {
  font-family: 'PlexMonoRegular';
  font-size: 1rem;
  color: #D6D6D6;
  position: absolute;
  margin-top: 2.2rem;
  margin-left: 4rem;
  font-weight: 100;
}

.gab { background-image: url('../assets/platforms/gab.png'); }
.parler { background-image: url('../assets/platforms/parler.png'); }
.facebook { background-image: url('../assets/platforms/facebook.png'); }
.twitter { background-image: url('../assets/platforms/twitter.png'); }
.instagram { background-image: url('../assets/platforms/instagram.png'); }
.rumble { background-image: url('../assets/platforms/rumble.png'); }
.dlive { background-image: url('../assets/platforms/dlive.png'); }
.twitch { background-image: url('../assets/platforms/twitch.png'); }
.bitchute { background-image: url('../assets/platforms/bitchute.png'); }
.trovo { background-image: url('../assets/platforms/trovo.png'); }
.odysee { background-image: url('../assets/platforms/odysee.png'); }
.ugetube { background-image: url('../assets/platforms/ugetube.png'); }
.lbry { background-image: url('../assets/platforms/lbry.png'); }
.brighteon { background-image: url('../assets/platforms/brighteon.png'); }
.locals {
  background-image: url('../assets/platforms/locals.png');
}

.analysis__container {
  display: flex;
  /* background: white; */
  margin-top: 6rem;
}

.analysis__50 {
  width: 66.66%;
  padding-right: 2rem;
}

.analysis__50 h3 {
  font-family: "PlexMonoRegular";
  font-size: 1.25rem;
  color: #D6D6D6;
  text-transform: uppercase;
  font-weight: 100;
}

.analysis {
  font-family: 'Ilisarniq-Regular';
  font-size: 1.25rem;
  color: white;
  line-height: 1.7rem;

}

.methodology__container {
  background: #333;
  padding: 2rem;
  margin-top: 2rem;
}

.methodology__container p {  
  font-family: 'Ilisarniq-Light';
  color: rgba(212, 212, 212, 1);
  font-size: 1rem;
  line-height: 1.4rem;
}

.methodology__50 {
  width: 33.33%;
}

.methodology__50 h3 {
  font-family: "PlexMonoRegular";
  font-size: 1rem;
  color: #D6D6D6;
  text-transform: uppercase;
  font-weight: 100;
}
</style>